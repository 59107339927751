<template>
  <div>
    <heade></heade>
    <div class="com" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="com_box">
        <div>
          <div class="com-left">
            <div class="com-left-top">{{ practice.name }}</div>
            <!-- <div class="com-left-com">
              <div class="com-left-com-item">·引言·</div>
            </div> -->
            <div class="com-left-foot">
              <div class="nice-scroll">
                <div v-for="(item, index) in practice_video_course_division" :key="index" class="navgatorLi" @click="
                  getPracticeMedicine(practice.id, item.course_vid, item.division_content_id,)
                  " :id="'trigger' + index">
                  <p :class="isActive == item.division_content_id
                    ? 'com-left-foot-but active'
                    : 'com-left-foot-but'
                    ">
                    {{ item.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="com-right">
            <el-button type="text" icon="el-icon-arrow-left" @click="$router.back(-1)">返回上一页</el-button>
            <div v-if="options.vid != ''" class="prism-player-box">
              <vue-aliplayer-v2 ref="VueAliplayerV2" :options="options" />
            </div>
            <div v-if="options.vid == ''" class="practice_medicine_list_box nice-scroll">
              <ul v-for="(
                  item, index
                ) in practiceMainReadDivisions" :key="index" class="practice_medicine_list">
                <li @click="
                  getDivisionDetail(item.id, item.charge_mode_id, index)
                  ">
                  {{ index + 1 }}. {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="tip" v-if="options.vid == '' && practiceMainReadDivisions == ''">
              该部分的视频课程资源尚未更新，您可先阅读主要阅读里面的文字资料
            </div>
          </div>
        </div>
        <div class="com-btt">
          <div>
            <div>
              <router-link v-for="(item, index) in practiceTheme" :key="index" :to="{
                path: path,
                query: { id: item.id },
              }" class="com-bbt-item">
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import $store from "../../store";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { scroll } from "@utils/scroll";
import VueAliplayerV2 from "vue-aliplayer-v2";
import {
  getPracticeMedicinesByGradeCategoryId,
  getPracticeGradeCategorys,
  getPracticeMedicinesMainReadByGradeCategoryId,
  getVideoPlayAuth,
} from "@api/public";
export default {
  components: {
    heade,
    foot,
    VueAliplayerV2,
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    practice() {
      this.$nextTick(function () {
        scroll($(".nice-scroll"));
      });
    },
  },
  props: {},
  data() {
    return {
      practice: {},
      practice_video_course_division: {},
      queryWhere: {},
      practiceTheme: [],
      content: "",
      backgroud: "",
      path: "",
      detailPath: "",
      practiceMainReadDivisions: "",
      isActive: "",
      options: {
        autoplay: false,
        height: "100%",
        playsinline: true,
        vid: "",
        playauth: "",
        encryptType: 1,
        cover: "",
      },
    };
  },
  mounted: function () {
    let that = this;
    getPracticeMedicinesByGradeCategoryId(that.queryWhere).then(function (res) {
      that.$set(that, "practice", res.data);
      that.$set(
        that,
        "practice_video_course_division",
        res.data.practice_medicines.course_division_category[0].course_division
      );
      that.$set(that.options, "vid", res.data.practice_medicines.course_vid);
      that.$set(that.options, "playauth", res.data.practice_medicines.play_auth);
      that.$set(
        that,
        "isActive",
        res.data.practice_medicines.course_division_category[0]
          .course_division[0].division_content_id
      );
    });

    getPracticeGradeCategorys().then(function (res) {
      that.$set(that, "practiceTheme", res.data.list);
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.path = this.$route.meta.path;
    this.detailPath = this.$route.meta.detailPath;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
  methods: {
    getPracticeMedicine: function (pacticeId, courseVid, divisionContentId) {
      this.isActive = divisionContentId;

      if (courseVid != "") {
        this.getVideoPlayAuth(courseVid);
      } else {
        this.options.vid = "";
        this.getPracticeMedicinesMainReadByGradeCategoryId(pacticeId);
      }
    },

    getVideoPlayAuth: function (courseVid) {
      let that = this;
      getVideoPlayAuth({ course_vid: courseVid }).then((res) => {
        that.$set(that.options, "vid", courseVid);
        that.$set(that.options, "playauth", res.data.videoPlayAuth.playAuth);
        that.practiceMainReadDivisions = "";
        console.log(this.practiceMainReadDivisions);
      });
    },
    getPracticeMedicinesMainReadByGradeCategoryId: function (gradeId) {
      let that = this;
      getPracticeMedicinesMainReadByGradeCategoryId({ id: gradeId }).then(
        (res) => {
          that.$set(that, "practiceMainReadDivisions", res.data);
          that.$set(that.options, "vid", "");
          that.$set(that.options, "playauth", "");
        }
      );
    },
    getDivisionDetail: function (id, charge_mode, parentId, index) {
      if (
        charge_mode == 1 ||
        ($store.state.app.userInfo != null &&
          $store.state.app.userInfo.is_empower)
      ) {
        window.location.href = this.detailPath + "?id=" + id;
      } else {
        if (!$store.state.app.token) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content: "您还未登录！是否去登录？",
              btn: ["是", "否"],
              closeBtn: 0,
              skin: "layui-layer-molv",
              yes: function () {
                window.location.href = "/login";
              },
            });
          });
        } else if (!$store.state.app.userInfo.is_empower) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content:
                "该部分内容为课题实验校、示范区会员账号专属内容，如需加入课题实验，请点击下方“课题申报”链接，按照要求提交信息，经课题组审核批准后，方可成为课题实验校、示范区，获得专属课题账号，正常查看全部内容。",
              closeBtn: 0,
              btn: ["课题申报", "关闭"],
              success: function (layero) {
                var btn = layero.find(".layui-layer-btn");
                btn.find(".layui-layer-btn0").attr({
                  href: "/application/shenbaobiao",
                });
              },
            });
          });
        }
      }
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>
<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.com_box {
  width: 1484px;
  height: 1660px;
  margin: 0 auto;
  position: relative;
}

.com-left-com-item {
  width: 100%;
}

.com-btt {
  width: 911px;
  font-size: 14px;
}

.com-btt>div>div {
  width: 640px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 193px;
  top: 8px;
}

/* 隐藏滚动条 */
.com-left-foot::-webkit-scrollbar {
  display: none;
}

.com-bbt-item {
  width: 15px;
  font-size: 18px;
}

.el-button--text {
  color: rgb(121, 0, 0);
}

.practice_medicine_list_box {
  position: relative;
  height: auto;
  max-height: 640px;
  padding-left: 15px !important;
  margin-top: 15px !important;
}

.practice_medicine_list {
  position: relative;
  height: 100%;
}

.practice_medicine_list li {
  font-size: 14px;
  letter-spacing: 2;
  margin-bottom: 35px;
  cursor: pointer;
  color: #333;
}

.com-left-foot-but.active {
  color: #d8c8a5;
}

.practice_medicine_list_cate {
  width: 100%;
  font-size: 12px;
  margin-bottom: 20px;
}

.practice_medicine_list_cate_item {
  width: 100%;
  color: #000;
  display: block;
  font-size: 24px;
  font-family: kaiti;
}

.prism-player-box {
  position: relative;
  height: 90% !important;
  width: 100%;
  z-index: 1000;
}

.prism-player-box .prism-player {
  height: 100% !important;
}

.tip {
  text-indent: 2em;
  font-size: 20px;
  color: #000;
  text-decoration: none;
  display: block;
  font-family: "楷体";
}
</style>
