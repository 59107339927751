var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heade'),_c('div',{staticClass:"com",style:({
    backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
  })},[_c('div',{staticClass:"com_box"},[_c('div',[_c('div',{staticClass:"com-left"},[_c('div',{staticClass:"com-left-top"},[_vm._v(_vm._s(_vm.practice.name))]),_c('div',{staticClass:"com-left-foot"},[_c('div',{staticClass:"nice-scroll"},_vm._l((_vm.practice_video_course_division),function(item,index){return _c('div',{key:index,staticClass:"navgatorLi",attrs:{"id":'trigger' + index},on:{"click":function($event){return _vm.getPracticeMedicine(_vm.practice.id, item.course_vid, item.division_content_id,)}}},[_c('p',{class:_vm.isActive == item.division_content_id
                  ? 'com-left-foot-but active'
                  : 'com-left-foot-but'},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)])]),_c('div',{staticClass:"com-right"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-arrow-left"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v("返回上一页")]),(_vm.options.vid != '')?_c('div',{staticClass:"prism-player-box"},[_c('vue-aliplayer-v2',{ref:"VueAliplayerV2",attrs:{"options":_vm.options}})],1):_vm._e(),(_vm.options.vid == '')?_c('div',{staticClass:"practice_medicine_list_box nice-scroll"},_vm._l((_vm.practiceMainReadDivisions),function(item,index){return _c('ul',{key:index,staticClass:"practice_medicine_list"},[_c('li',{on:{"click":function($event){return _vm.getDivisionDetail(item.id, item.charge_mode_id, index)}}},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(item.name)+" ")])])}),0):_vm._e(),(_vm.options.vid == '' && _vm.practiceMainReadDivisions == '')?_c('div',{staticClass:"tip"},[_vm._v(" 该部分的视频课程资源尚未更新，您可先阅读主要阅读里面的文字资料 ")]):_vm._e()],1)]),_c('div',{staticClass:"com-btt"},[_c('div',[_c('div',_vm._l((_vm.practiceTheme),function(item,index){return _c('router-link',{key:index,staticClass:"com-bbt-item",attrs:{"to":{
              path: _vm.path,
              query: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)])])])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }